import { ACtx } from "@/store";
import State from "./state";
import { AudioUtils } from "@/utils";

export type SoundsState = {};

export const state: SoundsState = {};

export const mutations = {};

export const actions = {
  playLineWin({ state, commit, dispatch, getters }: ACtx, mode: "short" | "long") {
    const hasBonus = state.currentTicket?.has_free_tickets;

    if (mode === "short" && !hasBonus) {
      AudioUtils.playSound(
        state.userData.settings.mevaho_mode === "digit"
          ? "digit-short"
          : state.currentGameId === "loto-pamir-legends" && state.bonus.isOn
          ? "win-one-pamir-legends-bonus"
          : state.currentGameId === "loto-pamir-legends" && !state.bonus.isOn
          ? "win-one-pamir-legends"
          : state.currentGameId === "loto-pearl"
          ? "win-one-pearl"
          : "fruit-middle",
        0
      );
    } else if (mode === "long" && !hasBonus) {
      AudioUtils.playSound(
        state.userData.settings.mevaho_mode === "digit"
          ? "receipt-win-drop-sound"
          : state.currentGameId === "loto-pamir-legends" && state.bonus.isOn
          ? "win-three-pamir-legends-bonus"
          : state.currentGameId === "loto-pamir-legends" && !state.bonus.isOn
          ? "win-three-pamir-legends"
          : state.currentGameId === "loto-pearl"
          ? "win-three-pearl"
          : "win-drop-sound",
        0
      );
    }
  },
};

export const getters = {};
