import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe086e52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-bonus__container" }
const _hoisted_2 = {
  key: 0,
  class: "modal-bonus__wrapper"
}
const _hoisted_3 = { class: "modal-bonus__text" }
const _hoisted_4 = { class: "modal-bonus__text --color" }
const _hoisted_5 = { class: "modal-bonus__text" }
const _hoisted_6 = { class: "modal-bonus__text --color" }
const _hoisted_7 = {
  key: 1,
  class: "modal-bonus__wrapper"
}
const _hoisted_8 = { class: "modal-bonus__text --color" }
const _hoisted_9 = { class: "modal-bonus__text" }
const _hoisted_10 = { class: "modal-bonus__text --color" }
const _hoisted_11 = {
  key: 2,
  class: "modal-bonus__wrapper-end"
}
const _hoisted_12 = { class: "modal-bonus__text --white" }
const _hoisted_13 = { class: "modal-bonus__text --color" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "container",
    onLeave: _ctx.leave,
    duration: "500",
    appear: ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["modal-bonus", { ['--hidden']: !_ctx.isBonusModal }])
      }, [
        _createVNode(_Transition, {
          name: "slide",
          duration: "500",
          appear: ""
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.isStart && !_ctx.isBonusOn)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("modalBonus.text1")), 1),
                    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.bonusGames) + " " + _toDisplayString(_ctx.$t("modalBonus.text2")), 1),
                    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("modalBonus.text3")), 1),
                    _createElementVNode("p", _hoisted_6, "«" + _toDisplayString(_ctx.$t("buttonPlay")) + "»", 1)
                  ]))
                : (_ctx.isStart && _ctx.isBonusOn)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("modalBonus.text4")), 1),
                      _createElementVNode("p", _hoisted_9, [
                        _createTextVNode(_toDisplayString(_ctx.$t("modalBonus.text5")) + " ", 1),
                        _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.secondaryBonusGames), 1),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t("modalBonus.text6")), 1)
                      ])
                    ]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                      _createElementVNode("span", _hoisted_12, [
                        _createTextVNode(_toDisplayString(_ctx.$t("modalBonus.text7")) + " ", 1),
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.payout) + " " + _toDisplayString(_ctx.currencyIcon), 1)
                      ])
                    ]))
            ])
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }, 8, ["onLeave"]))
}