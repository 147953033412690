import { api } from "@/api";
import { ACtx } from "@/store";
import store from "@/store";
import State from "./state";
import { PromoState } from "@/types/promo";
import storeEvents from "@/store-events";

export const state: PromoState = {
  promo: {
    id: "",
    name: "",
    quantity: 0,
    combination: "combi-1",
    amount: 0,
    currency: 0,
  },
};

export const mutations = {
  updatePromo(state: State, value: PromoState) {
    const { promo } = value;

    if (promo) {
      state.promo.promo = promo;
    }
  },
};

export const getters = {};

export const actions = {
  async loadPromo({ state, commit, dispatch }: ACtx) {
    const promo = await api.getPromo();
    if (promo !== undefined) {
      commit("updatePromo", promo);
      commit("setShowPromoModal", { show: true, start: false });
      commit("setPromoIsOn", true);
      commit("setPromoTicketsLeft", 0);
    }
  },
};
