import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7005156f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "doubling-boxes-pamir__buttons" }
const _hoisted_2 = { class: "doubling-boxes-pamir__boxes" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["doubling-boxes-pamir", { '--disabled': _ctx.disabled || _ctx.isBetstop }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goPlay('left'))),
        class: "doubling-boxes-pamir__left"
      }, "LEFT"),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goPlay('right'))),
        class: "doubling-boxes-pamir__right"
      }, "RIGHT")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.currentImage,
        alt: "rock",
        class: "doubling-boxes-pamir__manAnimation"
      }, null, 8, _hoisted_3)
    ])
  ], 2))
}