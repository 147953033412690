import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-45ecf479"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-control__title" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input-control", { '--reverse': _ctx.reverse }])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", {
      class: _normalizeClass(["input-control__text", { '--white': !_ctx.showCurrency, '--center': _ctx.center, '--glow': _ctx.glow }])
    }, [
      (_ctx.showCurrency || _ctx.glow)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.showValue?.toFixed(2)), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.value), 1))
    ], 2)
  ], 2))
}