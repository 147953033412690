
import { defineComponent } from "vue";
import store from "@/store";
import { getCurrencyIcon } from "@/utils";
import { CLOSE_BONUS_IN_BONUS_MODAL } from "@/constants";

export default defineComponent({
  name: "modal-bonus",
  data() {
    return {
      applyTimer: undefined as number | undefined,
    };
  },
  computed: {
    isBonusModal(): boolean {
      return store.state.ui.showBonusModal.show;
    },
    isStart(): boolean {
      return store.state.ui.showBonusModal.start;
    },
    bonusDelay(): number {
      return store.state.settings.bonus_games.playout_delay;
    },
    payout(): string {
      return (store.state.ui.lastPayout / 100).toFixed(2);
    },
    currency(): number {
      return store.state.userData.currency;
    },
    currencyIcon(): string {
      return getCurrencyIcon(this.currency);
    },
    isAutostartOn(): boolean {
      return store.state.userData.settings.autogame_start === "on";
    },
    isGambusaki(): boolean {
      return (
        store.state.currentGameId === "loto-gambusaki-9" ||
        store.state.currentGameId === "loto-gambusaki-gold-9" ||
        store.state.currentGameId === "loto-khoja" ||
        store.state.currentGameId === "loto-kissaho" ||
        store.state.currentGameId === "loto-silk-way" ||
        store.state.currentGameId === "loto-pamir-legends" ||
        store.state.currentGameId === "loto-pearl"
      );
    },
    isBonusOn(): boolean {
      return store.state.bonus.isOn;
    },
    bonusGames(): number {
      return store.state.settings.bonus_games.num_games;
    },
    allBonusGames(): number {
      return store.state.bonus.numGames;
    },
    secondaryBonusGames(): number {
      return store.state.settings.bonus_games.secondary_num_games;
    },
  },
  methods: {
    leave(el: HTMLElement, done: Function) {
      // add transition classes to modal content
      const x = el.firstElementChild;
      if (x) {
        x.classList.add("slide-leave-active", "slide-leave-to");
      }
      setTimeout(() => {
        done();
      }, 250);
    },
  },
  watch: {
    isBonusModal(n) {
      if (n && this.isStart && this.isAutostartOn && this.isGambusaki && !this.isBonusOn) {
        this.applyTimer = window.setTimeout(() => {
          store.commit("setIsBonusInBonusProcessing", false);
          store.commit("setIsSkipDisabled", false);
          store.dispatch("makeBonusTicket");
        }, 10000);
      } else if (n && this.isStart && this.isGambusaki && this.isBonusOn) {
        this.applyTimer = window.setTimeout(() => {
          store.commit("setIsBonusInBonusProcessing", false);
          store.commit("setIsSkipDisabled", false);
          store.dispatch("makeBonusTicket");
        }, CLOSE_BONUS_IN_BONUS_MODAL);
      } else {
        window.clearTimeout(this.applyTimer);
      }
    },
  },
});
