
import { defineComponent } from "vue";
import store from "@/store";
import CombinationControl from "@/components/Panels/CombinationControl.vue";
import NominalControl from "@/components/Panels/NominalControl.vue";
import DisplayField from "@/components/Panels/DisplayField.vue";
import AutoGame from "@/components/Panels/AutoGame.vue";
import PlayGame from "@/components/Panels/PlayGame.vue";
import { AudioUtils, truncTwo } from "@/utils";
import ApplyDoubling from "@/components/Panels/ApplyDoubling.vue";
import { MultiButtonMode } from "@/store/ui";
import RefuseDoubling from "@/components/Panels/RefuseDoubling.vue";
import router from "@/router";
import CombinationButton from "@/components/UI/CombinationButton.vue";
import BigButton from "@/components/UI/BigButton.vue";
import NominalButton from "@/components/UI/NominalButton.vue";
import { CLOSE_DOUBLING_AFTER_LOST_TIMEOUT } from "@/constants";
import InformationButton from "@/components/Panels/InformationButton.vue";
import storeEvents from "@/store-events";
import { UpdateTicketState } from "@/types/tickets";
import SimpleButton from "@/components/UI/SimpleButton.vue";
import IconBrush from "@/icons/IconBrush.vue";

export default defineComponent({
  name: "control-panel",
  components: {
    IconBrush,
    SimpleButton,
    InformationButton,
    NominalButton,
    BigButton,
    CombinationButton,
    RefuseDoubling,
    ApplyDoubling,
    PlayGame,
    AutoGame,
    DisplayField,
    NominalControl,
    CombinationControl,
  },
  data() {
    return {
      autoPlay: false,
      sumPaid: !!store.state.ui.lastPayout
        ? store.state.ui.lastPayout
        : (undefined as undefined | number),
      doublingTimer: undefined as number | undefined,
    };
  },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    newNominal(): number | undefined {
      return store.state.createTicket.amount ? store.state.createTicket.amount / 100 : undefined;
    },
    newCombinationNumber(): number {
      return store.getters.newCombinationNumber;
    },
    balance(): number | undefined {
      return store.state.userData.balance;
    },
    sumAmount(): number | undefined {
      return store.state.createTicket.sumAmount;
    },
    buttonMode(): MultiButtonMode {
      return store.state.ui.multiButtonMode;
    },
    autoGame(): boolean {
      return store.state.ui.autoGame;
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
    isModalOn(): boolean {
      return store.getters.isModalMessageOpened;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    currentTicket(): UpdateTicketState | undefined {
      return store.state.currentTicket;
    },
    isBonusOn(): boolean {
      return store.state.ui.showBonusModal.show;
    },
    isPromoOn(): boolean {
      return store.state.ui.promoIsOn;
    },
    currentPayout(): number {
      return this.currentTicket && this.currentTicket.payout ? this.currentTicket.payout : 0;
    },
    isResetSplitDisabled(): boolean {
      return store.state.doubling.split === 0;
    },
    countGame(): number {
      return store.state.doubling.countGame;
    },
    isSplitDisabled(): boolean {
      return !(
        store.state.settings.doubling_game.split.enabled &&
        this.countGame + 1 >= store.state.settings.doubling_game.split.stage_number
      );
    },
    isMoreMin(): boolean {
      const expected = store.state.doubling.expectedWinnings;
      const min = store.state.settings.doubling_game.split.min_amount;
      const split = store.state.doubling.split;
      const tempResult = expected[this.countGame] / 100 / 2 ** (split + 1);
      const result = truncTwo(tempResult) * 100;
      return result < min;
    },
    isTakePayout(): boolean {
      return store.state.doubling.isTakePayout;
    },
    isSplitAvailable(): boolean {
      const split = store.state.settings.doubling_game.split;
      return split.enabled && split.stage_number > 0;
    },
    currentAmount(): number {
      return store.state.doubling.currentAmount;
    },
    deferredBalance(): number {
      return store.state.doubling.deferredBalance;
    },
    isFireSameStep(): boolean {
      return this.countGame >= store.state.settings.doubling_game.fireproof_stage.game_number;
    },
  },
  methods: {
    applyDoubling() {
      storeEvents.emit("stopAnimation");
      store.commit("setIsTakePayout", true);
      store.dispatch("startDoublingTimer");
      store.dispatch("getLastDoublingGames");
      if (!this.isFruitMode && !this.isDoublingOn) {
        AudioUtils.playSound("receipt-breakdown-winning", 0, 1);
      }
      if (this.isFruitMode) {
        router.push("/doubling");
      } else {
        this.isDoublingOn
          ? store.dispatch("applyDoublingGame")
          : store.commit("setIsDigitDoublingAnim", true);
      }
      store.commit("setIsDoublingOn", true);
      if (this.currentTicket && !this.isBonusOn) {
        store.commit("setLastPayout", this.currentTicket.payout);
      }
      store.commit("setShowBonusModal", { show: false, start: false });
      store.commit("setShowPromoModal", { show: false, start: false });
    },
    applyAutoDoubling() {
      AudioUtils.playSound("stop-autoplay", 0, 1);
      storeEvents.emit("stopAnimation");
      store.commit("setMakeTicketDisabled", false);
      store.commit("setIsTakePayout", true);
      store.commit("setMultiButtonMode", "doubling");
      store.dispatch("startDoublingTimer");
      if (!this.isFruitMode && !this.isDoublingOn) {
        AudioUtils.playSound("receipt-breakdown-winning", 0, 1);
      }
      if (this.isFruitMode) {
        router.push("/doubling");
      } else {
        this.isDoublingOn
          ? store.dispatch("applyDoublingGame")
          : store.commit("setIsDigitDoublingAnim", true);
      }
      store.commit("setIsDoublingOn", true);
      if (this.currentTicket && !this.isBonusOn) {
        store.commit("setLastPayout", this.currentTicket.payout);
      }
      store.commit("setShowBonusModal", { show: false, start: false });
      store.commit("setShowPromoModal", { show: false, start: false });
    },
    async refuseDoubling() {
      storeEvents.emit("stopAnimation");
      store.commit("setShowBonusModal", { show: false, start: false });
      store.commit("setMultiButtonMode", "autoGame");
      await store.dispatch("closeDoublingGame");
      store.commit("setLastPayout", 0);
      if (
        store.state.ui.promoTicketsLeft === store.state.promo.promo?.quantity &&
        store.state.ui.promoIsOn === true
      ) {
        store.commit("setPromoIsOn", false);
      }
      if (store.state.ui.lastStateIsOn === true) {
        await store.dispatch("loadPromo");
        store.commit("setLastStateIsOn", false);
      }
    },
    refuseAutoDoubling() {
      storeEvents.emit("stopAnimation");
      store.commit("setShowBonusModal", { show: false, start: false });
      store.commit("setShowPromoModal", { show: false, start: false });
      store.commit("setMultiButtonMode", "autoGame");
      store.dispatch("closeDoublingGame");
      store.commit("setMakeTicketDisabled", store.state.ui.autoGame);
    },
    split() {
      store.dispatch("splitAmount");
    },
    resetSplit() {
      store.dispatch("resetSplit");
    },
  },
  watch: {
    "$store.state.ui.lastPayout": function (n: number) {
      const delay = this.isDoublingOn && this.isFruitMode ? CLOSE_DOUBLING_AFTER_LOST_TIMEOUT : 0;
      if (n > 0) {
        this.sumPaid = n;
      } else {
        this.sumPaid = 0;
        setTimeout(() => {
          this.sumPaid = undefined;
        }, delay);
      }
    },
    "$store.state.doubling.confirmTimeout": function (n: number) {
      if (n === 0 && this.buttonMode === "doubling") {
        this.refuseDoubling();
      }
    },
    "$store.state.doubling.autoConfirmTimeout": function (n: number) {
      if (n === 0 && this.buttonMode === "autoDoubling") {
        this.refuseAutoDoubling();
      }
    },
    sumamount() {
      console.log(this.sumAmount);
    },
  },
});
