import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-195f867a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "display-field" }
const _hoisted_2 = { class: "display-field__container" }
const _hoisted_3 = {
  key: 0,
  class: "display-field__innerTitle"
}
const _hoisted_4 = { class: "display-field__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.inner)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["display-field__title", { '--invert': _ctx.invert }])
        }, _toDisplayString(_ctx.title), 3))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.inner)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.value !== undefined || _ctx.showZero)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["display-field__value", { '--center': _ctx.center, '--invert': _ctx.invert }])
            }, _toDisplayString(_ctx.formatMoney), 3))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}