import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52a17dc0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-draws" }
const _hoisted_2 = { class: "modal-draws__title" }
const _hoisted_3 = { class: "modal-draws__container" }
const _hoisted_4 = { class: "modal-draws__cells" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MiniCell = _resolveComponent("MiniCell")!
  const _component_InfoModal = _resolveComponent("InfoModal")!

  return (_openBlock(), _createBlock(_component_InfoModal, {
    "show-close": true,
    open: _ctx.open,
    onClose: _ctx.closeModal
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.$t(`lotteryName.${_ctx.gameId}`)), 1),
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("th", null, _toDisplayString(_ctx.$t("modalDraws.date")), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t("modalDraws.drawNumber")), 1),
              _createElementVNode("th", null, _toDisplayString(_ctx.$t("modalDraws.result")), 1)
            ])
          ]),
          _createElementVNode("tbody", null, [
            (_ctx.draws.length > 0)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.draws, (draw) => {
                  return (_openBlock(), _createElementBlock("tr", null, [
                    _createElementVNode("td", null, _toDisplayString(_ctx.formatTime(draw.calculated)), 1),
                    _createElementVNode("td", null, _toDisplayString(draw.id), 1),
                    _createElementVNode("td", null, [
                      _createElementVNode("div", _hoisted_4, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(draw.numbers, (value) => {
                          return (_openBlock(), _createBlock(_component_MiniCell, { value: value }, null, 8, ["value"]))
                        }), 256))
                      ])
                    ])
                  ]))
                }), 256))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["open", "onClose"]))
}