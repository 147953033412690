
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";
import SwitcherCheckbox from "@/components/UI/SwitcherCheckbox.vue";

export default defineComponent({
  name: "menu-wrapper",
  components: { SwitcherCheckbox, BurgerModal },
  data() {
    return {
      autoSettings: false,
    };
  },
  computed: {
    isMenuOpen(): boolean {
      return store.state.showMenu;
    },
    isGambusaki(): boolean {
      return (
        store.state.currentGameId === "loto-gambusaki-9" ||
        store.state.currentGameId === "loto-gambusaki-gold-9" ||
        store.state.currentGameId === "loto-khoja" ||
        store.state.currentGameId === "loto-kissaho" ||
        store.state.currentGameId === "loto-silk-way" ||
        store.state.currentGameId === "loto-pamir-legends" ||
        store.state.currentGameId === "loto-pearl"
      );
    },
    autoStart: {
      get(): boolean {
        return store.state.userData.settings.autogame_start === "on";
      },
      set(value: boolean) {
        store.dispatch("setAutogameStart", value ? "on" : "off");
      },
    },
    autoContinuation: {
      get(): boolean {
        return store.state.userData.settings.autogame_continuation === "on";
      },
      set(value: boolean) {
        store.dispatch("setAutogameContinuation", value ? "on" : "off");
      },
    },
  },
  methods: {
    closeMenu() {
      store.commit("setShowMenu", false);
    },
    showRulesModal() {
      store.dispatch("toggleModal", "rules");
    },
    showDrawsModal() {
      store.dispatch("toggleModal", "draws");
    },
    showSettings() {
      this.autoSettings = !this.autoSettings;
    },
  },
});
