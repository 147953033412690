import { ACtx } from "@/store";
import State from "./state";
import {
  MevahoMode,
  SoundMode,
  WidescreenMode,
  UserData,
  UserDataUpdate,
  UserSettings,
  AutogameStartMode,
  AutogameContinuationMode,
} from "@/types/user";
import { api } from "@/api";

export const state: UserData = {} as UserData;

export const mutations = {
  updateStoredUserData(state: State, newUserData: UserData) {
    state.userData = newUserData;
  },
  setMevahoMode(state: State, value: MevahoMode) {
    state.userData.settings.mevaho_mode = value;
  },
  setSound(state: State, value: SoundMode) {
    state.userData.settings.sound = value;
  },
  setWidescreen(state: State, value: WidescreenMode) {
    state.userData.settings.widescreen = value;
  },
  setAutogameStart(state: State, value: AutogameStartMode) {
    state.userData.settings.autogame_start = value;
  },
  setAutogameContinuation(state: State, value: AutogameContinuationMode) {
    state.userData.settings.autogame_continuation = value;
  },
};

export const actions = {
  updateStoredUserData({ commit, state, dispatch }: ACtx, data: UserData) {
    const scoreboardMode = data.settings ? data.settings.mevaho_mode : undefined;
    const soundMode = data.settings ? data.settings.sound : undefined;
    const widescreenMode = data.settings ? data.settings.widescreen : undefined;
    const autogameStartMode = data.settings ? data.settings.autogame_start : undefined;
    const autogameContinuationMode = data.settings
      ? data.settings.autogame_continuation
      : undefined;
    const settings: UserSettings = {
      mevaho_mode: scoreboardMode ?? "digit",
      sound: soundMode ?? "on",
      widescreen: widescreenMode ?? "off",
      autogame_start: autogameStartMode ?? "off",
      autogame_continuation: autogameContinuationMode ?? "off",
    };
    const tempData = { ...data, settings };
    commit("updateStoredUserData", tempData);
  },
  setMevahoMode({ commit, state, dispatch }: ACtx, value: MevahoMode) {
    commit("setMevahoMode", value);
    commit("setWidescreen", state.userData.settings.mevaho_mode === "fruit" ? "on" : "off");
    dispatch("callUpdateUserSettings");
  },
  setSound({ commit, state, dispatch }: ACtx, value: SoundMode) {
    commit("setSound", value);
    dispatch("callUpdateUserSettings");
  },
  setWidescreen({ commit, state, dispatch }: ACtx, value: SoundMode) {
    commit("setWidescreen", value);
    dispatch("callUpdateUserSettings");
  },
  setAutogameStart({ commit, state, dispatch }: ACtx, value: AutogameStartMode) {
    commit("setAutogameStart", value);
    dispatch("callUpdateUserSettings");
  },
  setAutogameContinuation({ commit, state, dispatch }: ACtx, value: AutogameContinuationMode) {
    commit("setAutogameContinuation", value);
    dispatch("callUpdateUserSettings");
  },
  async callUpdateUserSettings({ commit, state, dispatch }: ACtx) {
    if (!state.isGuest) {
      const payload: UserSettings = {
        mevaho_mode: state.userData.settings.mevaho_mode,
        sound: state.userData.settings.sound,
        widescreen: state.userData.settings.widescreen,
        autogame_continuation: state.userData.settings.autogame_continuation,
        autogame_start: state.userData.settings.autogame_start,
      };
      await api.updateUserData({ settings: payload });
    }
  },
};

export const getters = {};
